import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import type { Context } from '@nuxt/types'
import { Template, TemplateType } from '@/services/types/Template'

export default class TemplateService {
  $axios: NuxtAxiosInstance

  constructor (context: Context) {
    this.$axios = context.$axios
  }

  delete (tenantId: string, phoneNumberId: string, templateId: string, type: TemplateType) {
    return this.$axios.$patch(`/api/sms-templates/${tenantId}/${phoneNumberId}/${templateId}`, {
      deleted: true,
      id: templateId,
      template_type: type
    })
  }

  save (tenantId: string, phoneNumberId: string, template: Template, type: TemplateType) {
    return this.$axios.$post(`/api/sms-templates/${tenantId}/${phoneNumberId}`, { ...template, template_type: type })
  }

  update (tenantId: string, phoneNumberId: string, template: Template, type: TemplateType) {
    return this.$axios.$patch(`/api/sms-templates/${tenantId}/${phoneNumberId}/${template.id}`, { ...template, template_type: type })
  }

  getTemplates (tenantId: string, phoneNumberId: string, type: TemplateType, page: number, pageSize: number) {
    return this.$axios.$get(`/api/sms-templates/${tenantId}/${phoneNumberId}`, { params: { page_size: pageSize, page, type } })
  }
}
